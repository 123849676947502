/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import { useEffect, useState } from 'react';
import { useMaterialUIController } from 'context';

// next
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';

// PRO
import breakpoints from 'assets/theme/base/breakpoints';

// images
import contactBgImage from 'assets/img/bg-contact-card.jpg';

// utils
import validateEmail from 'utils/validateEmail';
import validatePhone from 'utils/validatePhone';

// MUI
import InputAdornment from '@mui/material/InputAdornment';

// MUI
const Card = dynamic(() => import('@mui/material/Card'), { loading: () => null });
const Container = dynamic(() => import('@mui/material/Container'), { loading: () => null });
const FormControl = dynamic(() => import('@mui/material/FormControl'), { loading: () => null });
const Grid = dynamic(() => import('@mui/material/Grid'), { loading: () => null });
const Tooltip = dynamic(() => import('@mui/material/Tooltip'), { loading: () => null });

// PRO
const MDBox = dynamic(() => import('components/MDBox'), { loading: () => null });
const MDTypography = dynamic(() => import('components/MDTypography'), { loading: () => null });

// MUI Icons
const Done = dynamic(() => import('@mui/icons-material/Done'), { loading: () => null });
const Email = dynamic(() => import('@mui/icons-material/Email'), { loading: () => null });
const GitHub = dynamic(() => import('@mui/icons-material/GitHub'), { loading: () => null });
const LocationOn = dynamic(() => import('@mui/icons-material/LocationOn'), { loading: () => null });
const PhoneEnabled = dynamic(() => import('@mui/icons-material/PhoneEnabled'), { loading: () => null });
const PriorityHigh = dynamic(() => import('@mui/icons-material/PriorityHigh'), { loading: () => null });

// PRO
const MDButton = dynamic(() => import('components/MDButton'), { loading: () => null });
const MDInput = dynamic(() => import('components/MDInput'), { loading: () => null });

function Contact({ setSnackbar }) {
  const [controller] = useMaterialUIController();
  const { sidenavColor, darkMode } = controller;
  const router = useRouter();
  // values
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) setMobileView(true);
      else setMobileView(false);
    }
    window.addEventListener('resize', displayMobileNavbar);
    displayMobileNavbar();
    return () => window.removeEventListener('resize', displayMobileNavbar);
  }, []);

  return (
    <Container>
      <Card sx={{ marginBottom: 6 }}>
        <Grid container item>
          <MDBox
            width="100%"
            // bgColor="white"
            borderRadius="xl"
            shadow="xl"
            sx={{ overflow: 'hidden' }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{ overflow: 'hidden' }}
              >
                <MDBox
                  width="100%"
                  height="100%"
                  sx={{
                    position: 'absolute',
                    zIndex: 3,
                    backgroundImage: ({
                      palette: { gradients },
                      functions: { rgba, linearGradient },
                    }) => `${linearGradient(
                      rgba(gradients.dark.main, 0.8),
                      rgba(gradients.dark.state, 0.8),
                    )}`, //
                    backgroundSize: 'cover',
                  }}
                />
                <MDBox sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  overflow: 'hidden',
                  zIndex: 0,
                  height: mobileView ? 397 : 934,
                  transform: mobileView ? 'scale(1.2)' : 'scale(1)',
                }}
                >
                  <Image
                    src={contactBgImage.src}
                    alt="background picture of the ocean"
                    width={mobileView ? 700 : 550}
                    height={mobileView ? 500 : 934}
                    style={{ zIndex: -1, top: 0, left: 0 }}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                  sx={{ position: 'relative', zIndex: 4 }}
                >
                  <MDBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                    <MDTypography variant="h3" color="white" mb={1}>
                      Contact Information
                    </MDTypography>
                    <MDTypography variant="body2" color="white" opacity={0.8} mb={3} fontWeight="regular">
                      Send me a message and my team will get back to you within 24 hours.
                    </MDTypography>
                    <MDBox display="flex" p={1}>
                      <MDTypography variant="button" color="white">
                        <PhoneEnabled fontSize="small" />
                      </MDTypography>
                      <MDTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        Clients only
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" color="white" p={1}>
                      <MDTypography variant="button" color="white">
                        <Email fontSize="small" />
                      </MDTypography>
                      <MDTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        Clients only
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" color="white" p={1}>
                      <MDTypography variant="button" color="white">
                        <LocationOn fontSize="small" />
                      </MDTypography>
                      <MDTypography
                        component="span"
                        variant="button"
                        color="white"
                        opacity={0.8}
                        ml={2}
                        fontWeight="regular"
                      >
                        Huntington, NY 11743
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={3} ml={-1}>
                      <Tooltip title="My repos are private but you can see my heatmap">
                        <span>
                          <MDButton
                            aria-label="GitHub profile"
                            href="https://github.com/codefox1"
                            target="_blank"
                            rel="noreferrer noopener"
                            variant="text"
                            color="white"
                            size="large"
                            iconOnly
                          >
                            <GitHub fontSize="small" />
                          </MDButton>
                        </span>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={12} lg={7}>
                <MDBox component="form" p={2} method="post">
                  <MDBox px={3} py={{ xs: 2, sm: 6 }}>
                    <MDTypography variant="h2" mb={1}>
                      Say Hi!
                    </MDTypography>
                    <MDTypography variant="body1" color="text" mb={2}>
                      Send me a message.
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={0.5} pb={3} px={3}>
                    <Grid container>
                      <Grid item xs={12} pr={1} mb={3}>
                        <MDInput
                          variant="standard"
                          label="My name is"
                          placeholder="Full Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          value={name}
                          required
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} pr={1} mb={3}>
                        <FormControl sx={{ width: '100%' }}>
                          <MDInput
                            variant="standard"
                            label="My email is"
                            placeholder="Email"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            InputProps={{
                              endAdornment: (
                                email !== '' && !validateEmail(email) ? (
                                  <Tooltip title="Not a valid email">
                                    <InputAdornment position="end">
                                      <PriorityHigh color="error" />
                                    </InputAdornment>
                                  </Tooltip>
                                ) : email !== '' && validateEmail(email) ? (
                                  <Tooltip title="Valid email">
                                    <InputAdornment position="end">
                                      <Done color="success" />
                                    </InputAdornment>
                                  </Tooltip>
                                ) : ''
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} pr={1} mb={3}>
                        <FormControl sx={{ width: '100%' }}>
                          <MDInput
                            variant="standard"
                            label="My phone is"
                            placeholder="Phone number"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={phone}
                            required
                            onChange={(e) => setPhone(e.target.value)}
                            InputProps={{
                              endAdornment: (
                                phone !== '' && !validatePhone(phone) ? (
                                  <Tooltip title="Not a valid phone number">
                                    <InputAdornment position="end">
                                      <PriorityHigh color="error" />
                                    </InputAdornment>
                                  </Tooltip>
                                ) : phone !== '' && validatePhone(phone) ? (
                                  <Tooltip title="Valid phone number">
                                    <InputAdornment position="end">
                                      <Done color="success" />
                                    </InputAdornment>
                                  </Tooltip>
                                ) : ''
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} pr={1} mb={3}>
                        <FormControl sx={{ width: '100%' }}>
                          <MDInput
                            variant="standard"
                            label="Your message"
                            placeholder="I want to say that..."
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            multiline
                            rows={6}
                            required
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            sx={{ textArea: { color: darkMode && '#fff' } }}
                          />
                          {message.length < 251 && (
                            <MDTypography variant="button">
                              {250 - message.length}
                              {' '}
                              characters left
                            </MDTypography>
                          )}
                          {message.length >= 251 && (
                            <MDTypography variant="button" color="warning">
                              WOAH!!! Too many characters
                            </MDTypography>

                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      ml="auto"
                    >
                      <MDButton
                        aria-label="Send Message"
                        variant="gradient"
                        color={sidenavColor}
                        disabled={
                          name === ''
                          || email === ''
                          || phone === ''
                          || message === ''
                          || !validateEmail(email)
                          || !validatePhone(phone)
                          || message.length > 250
                        }
                        onClick={async (e) => {
                          e.preventDefault();
                          const result = await fetch('/api/send-contact-form', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                              name,
                              email,
                              message,
                              phone,
                              pathname: router.pathname,
                            }),
                          })
                            .then((response) => response.json())
                            .then((response) => response.message)
                            .catch(() => 'error');
                          if (result !== 'success') {
                            setSnackbar({ title: 'Error!', content: "Message didn't go through. Try again. ", color: 'info' });
                          } else {
                            setSnackbar({ title: 'Success!', content: 'Message sent successfully! Yay!', color: 'success' });
                            setName('');
                            setEmail('');
                            setMessage('');
                            setPhone('');
                          }
                        }}
                      >
                        Send Message
                      </MDButton>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Card>
    </Container>
  );
}

export default Contact;
